import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import bottomWave from '../../assets/images/bottom-wave.svg';
import monsterYou from '../../assets/images/monster-guide-you.jpg';
import { PopupButton } from "@typeform/embed-react";
import TeamMemberCard from "../team/TeamMemberCard";

const GuidesGrid = () => {
    const {allStrapiTeamMember} = useStaticQuery(graphql`
        query {
            allStrapiTeamMember(
                sort: {fields: sort, order: ASC}
                filter: {team_types: {elemMatch: {type: {eq: "Guide"}}}}) {
                nodes {
                    ...TeamMemberCard    
                }
            }
        }
    `)

    return (
        <div className="flex wrap center gap-large">
            {allStrapiTeamMember.nodes.map((guide) => (
                <TeamMemberCard teamMember={guide} schedulable={true}/>
            ))}

            {/* <div className="flex column items-center w-25">
                <img src={monsterYou} style={{maxWidth: "210px"}} alt="" />
                <h4 class="text-center mt-3">You?</h4>
                <img className="mt-3" src={bottomWave} style={{maxWidth: "250px"}} alt="" />
                <p class="small grey-light bold text-center mt-4">Are you interested in becoming a guide?</p>
                <div className="flex center gap-small mt-4">
                <Link className="btn primary small" href="https://forms.gle/CFmQcjZYQv5CR3ya7" target="_blank">
                    JOIN OUR TEAM
                </Link>
                </div>
            </div> */}
        </div>
    )
}

export default GuidesGrid;