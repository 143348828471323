import Seo from "../components/Seo";
import React from "react";
import Layout from "../components/Layout";
import monsters from '../assets/images/monsters-guided.svg';
import chooseGuideImage from '../assets/images/monsters4-guided.png'
import iconTopDiv from '../assets/icons/icon-top-div-green.png';
import { Link } from "gatsby";
import iconHelm from '../assets/icons/icon-helm.png';
import IconWaveTitle from "../components/ui/titles/IconWaveTitle";
import TheThreeRules from "../components/ui/sections/TheThreeRules";
import GetTheApp from "../components/ui/sections/GetTheApp";
import WaveTitle from "../components/ui/titles/WaveTitle";
import waveCalm from '../assets/icons/icon-wavy-calm.svg';
import remoteSession from '../assets/images/remote-session.svg';
import GuidesGrid from "../components/guides/GuidesGrid";

const ChooseGuide = () => {
    return (
        <Layout>
            <Seo seo={{ metaTitle: "Choose a Guide" }} />
            <main>
                <section id="safe-harbor-introduction">
                    <div className="container small">
                        <div className="flex wrap between items-center">
                        <div className="flex column w-60">
                            <img className="w-60 mb-4" src={waveCalm} alt="" />
                            <h1 className="grey">Choose a Guide</h1>
                        </div>
                        {/* <img className="w-40" src={chooseGuideImage} alt="" /> */}
                        </div>
                    </div>
                </section>
                <section id="safe-harbor-recommendation" className="bg-purple relative">
                    <img src={iconTopDiv} className="absolute" style={{width: "150px", top: "0", left: "50%", transform: "translate(-50%, -50%)"}} />
                    <div className="container small">
                        <div className="flex column items-center gap">
                            <h1 className="blue text-center">Schedule a Free 20 Minute Remote Session</h1>
                            <img src={remoteSession} className="w-30 my-5" />
                            <p className="large white text-center light">We can remotely facilitate 1 or more initial Safe Harbor Method™ (SHM) sessions to get you up and running.</p>
                        </div> 
                    </div>
                </section>
                <section id="safe-harbor-our-guides" className="">
                    <div className="container small">
                        <IconWaveTitle title="Our Guides" icon={iconHelm} iconFormat='png' margin="mb-10" />
                        <GuidesGrid/>

                    </div>
                </section>
                <TheThreeRules title="Remember the Three Rules" />
                <GetTheApp/>
                <section>
                    <div className="container small flex column items-center">
                        <WaveTitle title="Ready to Take the Helm?" margin="my-5"/>
                        <div className="flex center gap">
                            <Link className="btn primary large" to="/self-guided-sessions">Self Guided Sessions</Link>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export default ChooseGuide;